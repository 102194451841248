import Flyout from './flyout';

const flyoutConfig = {
  name: 'flyoutNav',
  class: Flyout,
  querySelector: {
    close: '.flyout-nav__close',
  },
  querySelectorAll: {
    items: '.flyout-nav__menu-item',
  },
};

export default flyoutConfig;
