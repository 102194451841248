import { Component } from 'js-component-framework';

/**
 * Component for Flyout pane
 */
export default class Flyout extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    this.flyout = document.querySelector('.flyout-nav');
    this.hamburger = document.querySelector('.site-header__nav-main-toggle');
    this.contact = document.querySelector('.flyout-contact-button');

    this.setTriggers();

    this.flyout.style.display = 'block';
    this.contact.style.display = 'flex';
  }

  /**
   * Set event listener on close link.
   */
  setTriggers() {
    if (this.children.close) {
      this.children.close.addEventListener('click', () => {
        document.body.classList.remove('flyout-open');
      });
    }
    if (this.hamburger) {
      this.hamburger.addEventListener('click', () => {
        document.body.classList.add('flyout-open');
      });
    }
    if (this.flyout) {
      this.flyout.addEventListener('click', ({ target }) => {
        if (! target.classList.contains('flyout-nav__arrow')) return;
        target.classList.toggle('open');

        const subMenu = target.nextElementSibling;
        if (subMenu) {
          subMenu.classList.toggle('open');
        }
      });
    }
    Array.prototype.forEach.call(this.children.items, (item) => {
      item.addEventListener('click', () => {
        document.body.classList.remove('flyout-open');
      });
    });
    document.addEventListener('click', (evt) => {
      if (document.body.classList.contains('flyout-open')) {
        let targetElement = evt.target;
        if (targetElement === this.hamburger) {
          return;
        }
        do {
          if (targetElement === this.flyout) {
            return;
          }
          targetElement = targetElement.parentNode;
        } while (targetElement);

        document.body.classList.remove('flyout-open');
      }
    });
  }
}
