import { ComponentManager } from 'js-component-framework';
import domContentLoaded from '../../js/utils/domContentLoaded';
import flyoutConfig from '../../components/flyout-nav';
import '../../scss/flyout-nav/index.scss';

const manager = new ComponentManager('nbc-flyout-nav-components');

domContentLoaded(() => {
  manager.initComponents([
    flyoutConfig,
  ]);
});

if (module.hot) {
  module.hot.accept();

  manager.initComponents([
    flyoutConfig,
  ]);
}
